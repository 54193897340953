
import {defineComponent, reactive, toRefs, onBeforeMount, onMounted} from 'vue'

export default defineComponent({
  name: '',
  setup() {
    const data = reactive({
      num:0,
      date: [
        {day: 'MON', month: 'FEB', date: 1, actived: true},
        {day: 'TUE', month: 'FEB', date: 2, actived: false},
        {day: 'WED', month: 'FEB', date: 3, actived: false},
        {day: 'THU', month: 'FEB', date: 4, actived: false},
        {day: 'FRI', month: 'FEB', date: 5, actived: false},
        {day: 'SAT', month: 'FEB', date: 6, actived: false},
        {day: 'SUN', month: 'FEB', date: 7, actived: false},
        {day: 'SUN', month: 'FEB', date: 7, actived: false},
        {day: 'SUN', month: 'FEB', date: 7, actived: false},
        {day: 'SUN', month: 'FEB', date: 7, actived: false},
      ]
    })
    const leftArrow = ()=>{
      if (data.date.length < 8) {
        return
      }
      if (data.num < 0) {
        data.num += 1;
      }
    };
    const rightArrow = ()=>{
      if (data.date.length < 8) {
        return
      }
      if (data.num > -(data.date.length + data.num)) {
        data.num -= 1;
      }
    };
    const clickDate = ()=>{};
    onBeforeMount(() => {
    })
    onMounted(() => {
    })
    const refData = toRefs(data);
    return {
      ...refData,
      leftArrow,
      rightArrow,
      clickDate,
    }

  }
});
